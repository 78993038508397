<template lang="pug">
  router-view
</template>

<script>
export default {
  name: 'Index'
}
</script>

<style lang="scss">
</style>
